export const EmailConfirmComplete = () => {
  return (
    <div className="text-center my-12">
      メールアドレスのご本人確認が完了しました！
      <a
        href="https://userapp.taishokudaikou.auxcorp.com/login"
        target="_blank"
        rel="noreferrer"
        class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
      >
        こちら
      </a>
      のページからログインし、お支払いをお願いいたします。
    </div>
  );
};
