import React from "react";
import { Header } from "./components/headers/Header";
import { Footer } from "./components/footers/Footer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Home } from "./components/pages/Home";
import { Complete } from "./components/pages/Complete";
import { EmailConfirm } from "./components/pages/EmailConfirm";
import { EmailConfirmComplete } from "./components/pages/EmailConfirmComplete";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/complete">
          <Complete />
        </Route>
        <Route exact path="/email-confirm">
          <EmailConfirm />
        </Route>
        <Route exact path="/email-confirm/complete">
          <EmailConfirmComplete />
        </Route>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
