import React from "react";

const EmployeeTypeSelect = ({ value, onChange, required }) => {
  return (
    <select
      value={value}
      onChange={onChange}
      required={required}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    >
      <option value="">選択してください</option>
      <option value="0">正社員</option>
      <option value="1">契約社員</option>
      <option value="2">有期雇用契約社員</option>
      <option value="3">パート・アルバイト</option>
      <option value="999">その他</option>
    </select>
  );
};

export default EmployeeTypeSelect;
