import { useState } from "react";
import { Button, Modal } from "flowbite-react";
import Label from "../label/Label";
import { useHistory } from "react-router-dom";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import SubmitButton from "../buttons/SubmitButton";
import TextInputField from "../inputField/TextInputField";
import EmailInputField from "../inputField/EmailInputField";

export const EmailConfirm = () => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(
        "https://hv40ioqu13.execute-api.ap-northeast-1.amazonaws.com/emailConfirm/emailConfirm",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "UgtHbLHuwk2Vh7Gqajk8SaTiNBvgM91O2Eblaj6O",
          },
          body: JSON.stringify({
            email,
            confirmationCode,
          }),
        }
      );

      if (response.status === 200) {
        const result = await response.json();
        history.push("/email-confirm/complete");
      } else {
        setOpenModal(true);
      }
    } catch (error) {
      console.error("エラーが発生しました:", error);
      setOpenModal(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="max-w-4xl mx-auto p-6">
        <form onSubmit={handleSubmit}>
          <h2 className="text-4xl font-extrabold dark:text-white">
            メールアドレス本人確認
          </h2>
          <p className="my-4 text-lg text-gray-500">
            お客様情報をご入力ください。お客様情報の送信後、ユーザーアカウントが自動的に登録されます。またお申込フォーム送信後、お客様専属の退職エージェントより確認のお電話を致します。
          </p>
          <div className="mb-6">
            <Label name={"メールアドレス"}></Label>
            <EmailInputField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"taishokudaikou@auxcorp.com"}
              required={true}
            ></EmailInputField>
          </div>
          <div className="mb-6">
            <Label name={"検証コード"}></Label>
            <TextInputField
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              required={true}
            ></TextInputField>
          </div>
          <div>
            <SubmitButton
              text={"送信する"}
              loading={loading}
              disabled={loading}
            ></SubmitButton>
          </div>
        </form>
      </div>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
        dismissible
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              メールアドレス確認に失敗しました。
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="gray" onClick={() => setOpenModal(false)}>
                閉じる
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
