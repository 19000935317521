import { useState } from "react";
import Label from "../label/Label";
import TextInputField from "../inputField/TextInputField";
import EmailInputField from "../inputField/EmailInputField";
import PasswordInputField from "../inputField/PasswordInputField";
import PhoneInputField from "../inputField/PhoneInputField";
import ZipCodeInputField from "../inputField/ZipCodeInputField";
import CheckBox from "../checkBox/CheckBox";
import EmployeeTypeSelect from "../select/EmployeeTypeSelect";
import PrefectureSelect from "../select/PrefectureSelect";
import Hr from "../hr/Hr";
import SubmitButton from "../buttons/SubmitButton";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export const Home = () => {
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastNameKana, setLastNameKana] = useState("");
  const [firstNameKana, setFirstNameKana] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [employeeType, setEmployeeType] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [prefecture, setPrefecture] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [representativeLastName, setRepresentativeLastName] = useState("");
  const [representativeFirstName, setRepresentativeFirstName] = useState("");
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        "https://rwrpxt1u40.execute-api.ap-northeast-1.amazonaws.com/apply",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "DXvP1Umntl5BwZ5EQ3C5P5u8y6HMYaxf7TgU5fZX",
          },
          body: JSON.stringify({
            lastName,
            firstName,
            lastNameKana,
            firstNameKana,
            email,
            password,
            confirmPassword,
            phoneNumber,
            agreement,
            employeeType,
            zipCode,
            prefecture,
            address1,
            address2,
            address3,
            companyName,
            representativeLastName,
            representativeFirstName,
            terms,
          }),
        }
      );

      if (response.status === 200) {
        const result = await response.json();
        history.push("/complete");
      } else {
        setOpenModal(true);
      }
    } catch (error) {
      setOpenModal(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="max-w-4xl mx-auto p-6">
        <form onSubmit={handleSubmit}>
          <h2 className="text-4xl font-extrabold dark:text-white">
            お客様情報
          </h2>
          <p className="my-4 text-lg text-gray-500">
            お客様情報をご入力ください。お客様情報の送信後、ユーザーアカウントが自動的に登録されます。またお申込フォーム送信後、退職代行エージェントより確認のお電話を致します。
          </p>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <Label name={"姓"}></Label>
              <TextInputField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required={true}
              ></TextInputField>
            </div>
            <div>
              <Label name={"名"}></Label>
              <TextInputField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required={true}
              ></TextInputField>
            </div>
            <div>
              <Label name={"セイ"}></Label>
              <TextInputField
                value={lastNameKana}
                onChange={(e) => setLastNameKana(e.target.value)}
                required={true}
              ></TextInputField>
            </div>
            <div>
              <Label name={"メイ"}></Label>
              <TextInputField
                value={firstNameKana}
                onChange={(e) => setFirstNameKana(e.target.value)}
                required={true}
              ></TextInputField>
            </div>
            <div>
              <Label name={"電話番号"}></Label>
              <PhoneInputField
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={"09012655483"}
                required={true}
              ></PhoneInputField>
            </div>
            <div>
              <Label name={"雇用形態"}></Label>
              <EmployeeTypeSelect
                value={employeeType}
                onChange={(e) => setEmployeeType(e.target.value)}
                required={true}
              ></EmployeeTypeSelect>
            </div>
          </div>
          <div className="mb-6">
            <Label name={"メールアドレス"}></Label>
            <EmailInputField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={"taishokudaikou@auxcorp.com"}
              required={true}
            ></EmailInputField>
          </div>
          <div className="mb-6">
            <Label name={"パスワード"}></Label>
            <PasswordInputField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={"•••••••••"}
              required={true}
            ></PasswordInputField>
          </div>
          <div className="mb-6">
            <Label name={"パスワードの確認"}></Label>
            <PasswordInputField
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={"•••••••••"}
              required={true}
            ></PasswordInputField>
          </div>
          <Hr></Hr>
          <div className="mb-6">
            <CheckBox
              checked={agreement}
              onChange={(e) => setAgreement(e.target.checked)}
              label={"退職の意思表示に同意します。"}
              required={true}
              id={"agreement-checkbox"}
              forText={"agreement-checkbox"}
            ></CheckBox>
          </div>
          <Hr></Hr>
          <h2 className="text-4xl font-extrabold dark:text-white">
            勤務先情報
          </h2>
          <p className="my-4 text-lg text-gray-500">
            お客様の勤務先情報をご入力ください。弊社にて確認後、退職届を勤務先へお送りいたします。
          </p>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <Label name={"郵便番号"}></Label>
              <ZipCodeInputField
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                required={true}
              ></ZipCodeInputField>
            </div>
            <div>
              <Label name={"都道府県"}></Label>
              <PrefectureSelect
                value={prefecture}
                onChange={(e) => setPrefecture(e.target.value)}
                required={true}
              ></PrefectureSelect>
            </div>
            <div>
              <Label name={"市区町村"}></Label>
              <TextInputField
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                required={true}
              ></TextInputField>
            </div>
            <div>
              <Label name={"番地"}></Label>
              <TextInputField
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                required={true}
              ></TextInputField>
            </div>
            <div>
              <Label name={"建物名・部屋番号"}></Label>
              <TextInputField
                value={address3}
                onChange={(e) => setAddress3(e.target.value)}
              ></TextInputField>
            </div>
          </div>
          <div className="mb-6">
            <Label name={"会社名"}></Label>
            <TextInputField
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required={true}
            ></TextInputField>
          </div>
          <div>
            <Label name={"代表者名"}></Label>
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <Label name={"姓"}></Label>
              <TextInputField
                value={representativeLastName}
                onChange={(e) => setRepresentativeLastName(e.target.value)}
                required={true}
              ></TextInputField>
            </div>
            <div>
              <Label name={"名"}></Label>
              <TextInputField
                value={representativeFirstName}
                onChange={(e) => setRepresentativeFirstName(e.target.value)}
                required={true}
              ></TextInputField>
            </div>
          </div>
          <Hr></Hr>
          <div className="mb-6">
            <CheckBox
              checked={terms}
              onChange={(e) => setTerms(e.target.checked)}
              label={"利用規約に同意します。"}
              required={true}
              id={"terms-checkbox"}
              forText={"terms-checkbox"}
            ></CheckBox>
          </div>
          <div>
            <SubmitButton
              text={"送信する"}
              loading={loading}
              disabled={loading}
            ></SubmitButton>
          </div>
        </form>
      </div>
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
        dismissible
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              お申込フォームの送信に失敗しました。
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="gray" onClick={() => setOpenModal(false)}>
                閉じる
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
